export default {
  seo: {
    title: 'Финансовая отчетность за 2024 год | Банк 131',
    description: 'Финансовая отчетность за 2024 год',
  },
  title: 'Финансовая отчетность за 2024 год',
  content: [
    {
      title: '',
      link: '',
      clarification: {
        label: 'Дата размещения 02.05.2024',
        text:
          'Дата проведения очередного (годового) общего собрания участников (дата принятия решения единственного участника Общества с ограниченной ответственностью «Банк 131») — 26.04.2024',
      },
    },
  ],
};
